import { getStockSuppliers, addStockSupplier, editStockSupplier, deleteStockSupplier, bulkUpdateStockSuppliers } from '../../config/config'
import { toast } from 'react-toastify'
import { handleError } from '../../utils/utils'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from '../../redux/actions/AuthActions'

export const GET_STOCK_SUPPLIERS_REQUEST = 'GET_STOCK_SUPPLIERS_REQUEST'
export const GET_STOCK_SUPPLIERS_SUCCESS = 'GET_STOCK_SUPPLIERS_SUCCESS'
export const GET_STOCK_SUPPLIERS_ERROR = 'GET_STOCK_SUPPLIERS_ERROR'

export const ADD_STOCK_SUPPLIER_STARTED = 'ADD_STOCK_SUPPLIER_STARTED'
export const ADD_STOCK_SUPPLIER_END = 'ADD_STOCK_SUPPLIER_END'

export const EDIT_STOCK_SUPPLIER_STARTED = 'EDIT_STOCK_SUPPLIER_STARTED'
export const EDIT_STOCK_SUPPLIER_END = 'EDIT_STOCK_SUPPLIER_END'

export const DELETE_STOCK_SUPPLIER_STARTED = 'DELETE_STOCK_SUPPLIER_STARTED'
export const DELETE_STOCK_SUPPLIER_END = 'DELETE_STOCK_SUPPLIER_END'

export const BULK_UPDATE_STOCK_SUPPLIERS_STARTED = 'BULK_UPDATE_STOCK_SUPPLIERS_STARTED'
export const BULK_UPDATE_STOCK_SUPPLIERS_END = 'BULK_UPDATE_STOCK_SUPPLIERS_END'

export const IMPORT_STOCK_SUPPLIERS_STARTED = 'IMPORT_STOCK_SUPPLIERS_STARTED'
export const IMPORT_STOCK_SUPPLIERS_ENDED = 'IMPORT_STOCK_SUPPLIERS_ENDED'

export const SET_STOCK_SUPPLIER_POPUP = 'SET_STOCK_SUPPLIER_POPUP'
export const CLEAR_ALL_STOCK_SUPPLIER = 'CLEAR_ALL_STOCK_SUPPLIER'

export const CLEAR_STOCK_SUPPLIER_LOADING = "CLEAR_STOCK_SUPPLIER_LOADING"

const actions = {
  getStockSuppliers: () => async (dispatch) => {
      dispatch({
        type: GET_STOCK_SUPPLIERS_REQUEST
      })
    await getStockSuppliers().then((res) => {
      if (res?.status === 200) {
        console.log(res?.data?.suppliers)
        dispatch({
          type: GET_STOCK_SUPPLIERS_SUCCESS,
          payload: {
            suppliers: res?.data?.suppliers,
          },
        })
      } else {
        handleError(dispatch)(res, GET_STOCK_SUPPLIERS_ERROR, 'GET STOCK SUPPLIERS ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Stock Suppliers error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
        dispatch({
          type: GET_STOCK_SUPPLIERS_ERROR,
          payload: {
            message: parseError(res?.data?.error) || res?.data?.message || 'GET STOCK SUPPLIERS error',
          },
        })
      }
    })
  },

  addStockSupplier: (data) => async (dispatch) => {
    dispatch({
      type: ADD_STOCK_SUPPLIER_STARTED,
    })
    await addStockSupplier(data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_STOCK_SUPPLIERS_SUCCESS,
          payload: {
            suppliers: res?.data?.suppliers,
          },
        })
        dispatch({
          type: SET_STOCK_SUPPLIER_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD STOCK SUPPLIER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add Stock Supplier Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: ADD_STOCK_SUPPLIER_END,
    })
  },

  editStockSupplier: (uuid, data, setSelectedInfo) => async (dispatch) => {
    dispatch({
      type: EDIT_STOCK_SUPPLIER_STARTED,
    })
    await editStockSupplier(uuid, data).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_STOCK_SUPPLIERS_SUCCESS,
          payload: {
            suppliers: res?.data?.suppliers,
          },
        })
        dispatch({
          type: SET_STOCK_SUPPLIER_POPUP,
          payload: false,
        })
        setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT STOCK SUPPLIER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit Supplier Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: EDIT_STOCK_SUPPLIER_END,
    })
  },

  deleteStockSupplier: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_STOCK_SUPPLIER_STARTED,
    })
    await deleteStockSupplier(uuid).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_STOCK_SUPPLIERS_SUCCESS,
          payload: {
            suppliers: res?.data?.suppliers,
          },
        })
        dispatch({
          type: SET_STOCK_SUPPLIER_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE STOCK SUPPLIER ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete Stock Supplier Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403) {
        //   window.location = '/forbidden'
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // }
      }
    })
    dispatch({
      type: DELETE_STOCK_SUPPLIER_END,
    })
  },

  bulkUpdateStockSuppliers: (data, resetForm, setIsEditing) => async (dispatch) => {
        dispatch({
          type: BULK_UPDATE_STOCK_SUPPLIERS_STARTED
        });
        console.log('act start')
        let success = true
        let message = 'EDIT BULK STOCK SUPPLIERS SUCCESS'
        try {
          for (const item of data) {
            const response = await bulkUpdateStockSuppliers({ suppliers: item });
    
            if (response.status === 200) {
              message = response?.data?.message || 'EDIT BULK STOCK SUPPLIERS SUCCESS'
              //  toast.success(response?.data?.message || 'EDIT STOCK SUPPLIERS SUCCESS');
              dispatch({
                type: GET_STOCK_SUPPLIERS_SUCCESS,
                payload: {
                  suppliers: response?.data?.suppliers
                }
              });
            } else {
              success = false
              handleError(dispatch)(response, null, 'EDIT BULK ERROR');
            }
          }
          if (success) {
            toast.success(message)
            setIsEditing(false);
          }
          //  else resetForm()
        } catch (error) {
          console.error(error);
          toast.error('An unexpected error occurred during the bulk edit.');
        } finally {
          dispatch({
            type: BULK_UPDATE_STOCK_SUPPLIERS_END
          });
        }
  },
  
  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_STOCK_SUPPLIER_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_STOCK_SUPPLIER,
    })
  },
}

export default actions
