import React from 'react'
import { connect } from 'react-redux'
import { CircularProgress, Button, Box } from '@mui/material'
import { getField, getTranslation } from '../../utils/utils'

function LoadingButton({ title, isSubmitting, float, disabled, ...props }) {
  const dictionary = getTranslation('loading-button', props.translation)
  return (
    <Button {...props}
      disabled={isSubmitting || disabled}
      style={{ display: 'flex', flexDirection: 'column', float: float }}>
      {isSubmitting ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-netween' }}>
          <CircularProgress
            size={20}
            sx={{
              color: 'white',
            }}
          />
          <Box sx={{ margin: '0px 5px 0px 5px' }}>
            {getField(dictionary, 'saving', props.language)}
          </Box>
        </Box>
      ) : (
        title
      )}
    </Button>
  )
}
const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.translation.language
})
export default connect(mapStateToProps)(LoadingButton)
