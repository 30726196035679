import {
  getTypesAndAllergies,
  addTypesAndAllergies,
  editTypesAndAllergies,
  deleteTypesAndAllergies,
  bulkUpdateTypes
} from '../../config/config'
import { toast } from 'react-toastify'
import { parseError } from '../../utils/parseError'
import { LOGOUT_USER, SET_PERMISSIONS } from './AuthActions'
import { handleError } from '../../utils/utils'

export const GET_TYPES_AND_ALLERGIES_REQUEST = 'GET_TYPES_AND_ALLERGIES_REQUEST'
export const GET_TYPES_AND_ALLERGIES_SUCCESS = 'GET_TYPES_AND_ALLERGIES_SUCCESS'
export const GET_TYPES_AND_ALLERGIES_ERROR = 'GET_TYPES_AND_ALLERGIES_ERROR'

export const ADD_TYPES_AND_ALLERGIES_STARTED = 'ADD_TYPES_AND_ALLERGIES_STARTED'
export const ADD_TYPES_AND_ALLERGIES_END = 'ADD_TYPES_AND_ALLERGIES_END'

export const EDIT_TYPES_AND_ALLERGIES_STARTED = 'EDIT_TYPES_AND_ALLERGIES_STARTED'
export const EDIT_TYPES_AND_ALLERGIES_END = 'EDIT_TYPES_AND_ALLERGIES_END'

export const DELETE_TYPES_AND_ALLERGIES_STARTED = 'DELETE_TYPES_AND_ALLERGIES_STARTED'
export const DELETE_TYPES_AND_ALLERGIES_END = 'DELETE_TYPES_AND_ALLERGIES_END'

export const SET_TYPES_AND_ALLERGIES_POPUP = 'SET_TYPES_AND_ALLERGIES_POPUP'
export const CLEAR_ALL_TYPES_AND_ALLERGIES = 'CLEAR_ALL_TYPES_AND_ALLERGIES'

export const BULK_UPDATE_TYPES_AND_ALLERGIES_STARTED = 'BULK_UPDATE_TYPES_AND_ALLERGIES_STARTED'
export const BULK_UPDATE_TYPES_AND_ALLERGIES_ENDED = 'BULK_UPDATE_TYPES_AND_ALLERGIES_ENDED'

export const CLEAR_TYPES_LOADING = 'CLEAR_TYPES_LOADING'

const actions = {
  bulkUpdateTypes: (ruuid, data, reset, setIsEditing) => async (dispatch) => {
    // dispatch({
    //   type: BULK_UPDATE_TYPES_AND_ALLERGIES_STARTED
    // })
    // await bulkUpdateTypes(ruuid, data).then((response) => {
    //   if (response.status === 200) {
    //     toast.success(response?.data?.message || 'BULK UPDATE FOOD TYPES AND ALLERGIES SUCCESS')
    //     dispatch({
    //       type: GET_TYPES_AND_ALLERGIES_SUCCESS,
    //       payload: { typesAndAllergies: response.data.allTypesAndAllergies }
    //     })
    //     setIsEditing(false)
    //   } else {
    //     reset()
    //     handleError(dispatch)(response, null, 'BULK UPDATE FOOD TYPES AND ALLERGIES ERROR')
    //   }
    // })
    // dispatch({
    //   type: BULK_UPDATE_TYPES_AND_ALLERGIES_ENDED
    // })
    dispatch({
      type: BULK_UPDATE_TYPES_AND_ALLERGIES_STARTED
    });
    let success = true
    let message = 'EDIT BULK TYPES SUCCESS'
    try {
      for (const item of data) {
        const response = await bulkUpdateTypes(ruuid, { typesAndAllergies: item });

        if (response.status === 200) {
          message = response?.data?.message || 'EDIT BULK TYPES SUCCESS'
          //  toast.success(response?.data?.message || 'EDIT BULK PRODUCTS SUCCESS');
          dispatch({
            type: GET_TYPES_AND_ALLERGIES_SUCCESS,
            payload: {
              typesAndAllergies: response?.data?.allTypesAndAllergies
            }
          });
        } else {
          success = false
          handleError(dispatch)(response, null, 'EDIT BULK ERROR');
        }
      }
      if (success) {
        toast.success(message)
        setIsEditing(false);
      } else reset()
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred during the bulk edit.');
    } finally {
      dispatch({
        type: BULK_UPDATE_TYPES_AND_ALLERGIES_ENDED
      });
    }
  },
  getTypesAndAllergies: (ruuid) => async (dispatch, getState) => {
    const currentRestaurant = getState().typesAndAllergies.selectedRestaurant
    if (ruuid !== currentRestaurant)
      dispatch({
        type: GET_TYPES_AND_ALLERGIES_REQUEST,
        payload: ruuid
      })
    await getTypesAndAllergies(ruuid).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_TYPES_AND_ALLERGIES_SUCCESS,
          payload: {
            typesAndAllergies: res?.data?.foodtypesAndAlergies,
          },
        })
      } else {
        handleError(dispatch)(res, GET_TYPES_AND_ALLERGIES_ERROR, 'GET TYPES AND ALLERGIES ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Get All Types And Allergies error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
        // dispatch({
        //   type: GET_TYPES_AND_ALLERGIES_ERROR,
        //   payload: {
        //     message: parseError(res?.data?.error) || res?.data?.message || 'GET Types And Allergies error',
        //   },
        // })
      }
    })
  },

  addTypesAndAllergies: (ruuid, data) => async (dispatch) => {
    dispatch({
      type: ADD_TYPES_AND_ALLERGIES_STARTED,
    })
    await addTypesAndAllergies(ruuid, data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_TYPES_AND_ALLERGIES_SUCCESS,
          payload: {
            typesAndAllergies: res?.data?.foodTypesAndAllergies,
          },
        })
        dispatch({
          type: SET_TYPES_AND_ALLERGIES_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'ADD TYPES AND ALLERGIES ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Add TypesAndAllergies error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
      }
    })
    dispatch({
      type: ADD_TYPES_AND_ALLERGIES_END,
    })
  },

  editTypesAndAllergies: (ruuid, uuid, data, setSelectedInfo) => async (dispatch) => {
    dispatch({
      type: EDIT_TYPES_AND_ALLERGIES_STARTED,
    })
    await editTypesAndAllergies(ruuid, uuid, data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Saved')
        dispatch({
          type: GET_TYPES_AND_ALLERGIES_SUCCESS,
          payload: {
            typesAndAllergies: res?.data?.foodTypesAndAllergies,
          },
        })
        dispatch({
          type: SET_TYPES_AND_ALLERGIES_POPUP,
          payload: false,
        })
        setSelectedInfo({})
      } else {
        handleError(dispatch)(res, null, 'EDIT TYPES AND ALLERGIES ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Edit TypesAndAllergies error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
      }
    })
    dispatch({
      type: EDIT_TYPES_AND_ALLERGIES_END,
    })
  },

  deleteTypesAndAllergies: (ruuid, uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_TYPES_AND_ALLERGIES_STARTED,
    })
    await deleteTypesAndAllergies(ruuid, uuid).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message || 'Successfully Deleted')
        dispatch({
          type: GET_TYPES_AND_ALLERGIES_SUCCESS,
          payload: {
            typesAndAllergies: res?.data?.foodTypesAndAllergies,
          },
        })
        dispatch({
          type: SET_TYPES_AND_ALLERGIES_POPUP,
          payload: false,
        })
      } else {
        handleError(dispatch)(res, null, 'DELETE TYPES AND ALLERGIES ERROR')
        // if (res.status !== 401 && res.status !== 403)
        //   toast.error(res?.data?.message || 'Delete TypesAndAllergies Error')
        // if (res.status === 401) {
        //   dispatch({ type: LOGOUT_USER })
        // }
        // if (res.status === 403)
        //   dispatch({
        //     type: SET_PERMISSIONS,
        //     payload: res?.data?.permissions
        //   })
      }
    })
    dispatch({
      type: DELETE_TYPES_AND_ALLERGIES_END,
    })
  },

  setActionModal: (state) => async (dispatch) => {
    dispatch({
      type: SET_TYPES_AND_ALLERGIES_POPUP,
      payload: state,
    })
  },

  // CLEAR ALL Reducer
  clearAll: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_TYPES_AND_ALLERGIES,
    })
  },
}

export default actions
