let supportedLanguages = null

export const setSupportedLanguages = (languages) => {
    supportedLanguages = languages
}

export const getSupportedLanguages = () => {
    if (!supportedLanguages) {
        throw new Error("Supported languages have not been initialized")
    }
    return supportedLanguages
};
