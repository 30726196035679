import React from 'react'
import PropTypes from 'prop-types'
import { matchPath } from 'react-router-dom'
import { List } from '@mui/material'
import NavItem from './NavItem'
import { getField, getFieldData } from '../../utils/utils'

const reduceChildRoutes = ({ acc, pathname, item, depth, language, pagesDictionary }) => {
  const key = `${item.name.english}-${depth}`
  const match = item.route
    ? matchPath(pathname,
      {
        path: item.route + '*',
        exact: true,
      },
    )
    : false

  if (item.subPage?.length > 0) {
    acc.push(
      <NavItem
        active={match?.isExact ? true : false}
        depth={depth}
        icon={item.icon}
        // info={item.info}
        key={key}
        open={Boolean(match?.isExact)}
        path={item.route}
        title={getField(pagesDictionary, item.key, language)}
        priority={item.isRootMenu}
        sx={{
          display: 'flex',
          width: '100%'
        }}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.subPage,
          pathname,
          language,
          pagesDictionary
        })}
      </NavItem>,
    )
  } else {
    acc.push(
      <NavItem
        active={match?.isExact ? true : false}
        depth={depth}
        key={key}
        path={item.route}
        title={getField(pagesDictionary, item.key, language)}
        icon={item.icon}
        priority={item.isRootMenu}
      />,
    )
  }
  return acc

}

const renderNavItems = ({ depth = 0, items, pathname, language, pagesDictionary }) => (
  <List disablePadding>
    {items?.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
          language,
          pagesDictionary
        }),
      [],
    )}
  </List>
)

const NavSection = (props) => {
  const { data, pathname, language, pagesDictionary } = props
  return (
    <List disablePadding sx={{ margin: "0px" }}
    >
      {renderNavItems({
        items: data,
        pathname,
        language,
        pagesDictionary
      })}
    </List>
  )
}

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
}

export default NavSection
