import {
    GET_DASHBOARD_REQUEST,
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_ERROR,

    GET_DASHBOARD_TRANSACTION_REQUEST,
    GET_DASHBOARD_TRANSACTION_SUCCESS,
    GET_DASHBOARD_TRANSACTION_ERROR,

    GET_DASHBOARD_PRODUCTS_REQUEST,
    GET_DASHBOARD_PRODUCTS_SUCCESS,
    GET_DASHBOARD_PRODUCTS_ERROR,

    GET_DASHBOARD_CUSTOMERS_REQUEST,
    GET_DASHBOARD_CUSTOMERS_SUCCESS,
    GET_DASHBOARD_CUSTOMERS_ERROR,

    GET_DASHBOARD_TOTAL_REVENUE_REQUEST,
    GET_DASHBOARD_TOTAL_REVENUE_SUCCESS,
    GET_DASHBOARD_TOTAL_REVENUE_ERROR,

    GET_DASHBOARD_ORDERS_VALUE_REQUEST,
    GET_DASHBOARD_ORDERS_VALUE_SUCCESS,
    GET_DASHBOARD_ORDERS_VALUE_ERROR,

    GET_DASHBOARD_ORDERS_COUNT_REQUEST,
    GET_DASHBOARD_ORDERS_COUNT_SUCCESS,
    GET_DASHBOARD_ORDERS_COUNT_ERROR,

    GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST,
    GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS,
    GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR,

    GET_DASHBOARD_NEW_PRODUCTS_REQUEST,
    GET_DASHBOARD_NEW_PRODUCTS_SUCCESS,
    GET_DASHBOARD_NEW_PRODUCTS_ERROR,

    CLEAR_DASHBOARD_LOADING,

    SET_DASHBOARD_REST,

    GET_CONSOLIDATED_CURRENCY_REQUEST,
    GET_CONSOLIDATED_CURRENCY_SUCCESS,
    GET_CONSOLIDATED_CURRENCY_ERROR,

    GET_RESTAURANTS_CURRENCY_REQUEST,
    GET_RESTAURANTS_CURRENCY_SUCCESS,
    GET_RESTAURANTS_CURRENCY_ERROR,

    SET_CONSOLIDATED_CURRENCY_STARTED,
    SET_CONSOLIDATED_CURRENCY_ENDED,

    SET_CARDS_POSITIONING_STARTED,
    SET_CARDS_POSITIONING_ENDED,

    GET_CARDS_POSITIONING_REQUEST,
    GET_CARDS_POSITIONING_SUCCESS,
    GET_CARDS_POSITIONING_ERROR,

    SET_PRODUCT_LAYOUT,
    SET_CUSTOMERS_LAYOUT,
    SET_TODAY_LAYOUT,
    SET_TRANSACTIONS_LAYOUT,

} from '../actions/DashboardActions'

import {
    SET_DEFAULT_LAYOUT_DATA,

    GET_AVERAGE_PREPARATION_TIME_REQUEST,
    GET_AVERAGE_PREPARATION_TIME_SUCCESS,
    GET_AVERAGE_PREPARATION_TIME_ERROR,

    GET_NEW_CUSTOMERS_REQUEST,
    GET_NEW_CUSTOMERS_SUCCESS,
    GET_NEW_CUSTOMERS_ERROR,

    GET_FEEDBACKS_COMPLAINTS_REQUEST,
    GET_FEEDBACKS_COMPLAINTS_SUCCESS,
    GET_FEEDBACKS_COMPLAINTS_ERROR,

    GET_FEEDBACKS_RATING_REQUEST,
    GET_FEEDBACKS_RATING_SUCCESS,
    GET_FEEDBACKS_RATING_ERROR,

    GET_NOT_ACTIVE_CUSTOMERS_REQUEST,
    GET_NOT_ACTIVE_CUSTOMERS_SUCCESS,
    GET_NOT_ACTIVE_CUSTOMERS_ERROR,

    GET_FEEDBACKS_SUMMARY_NEGATIVE_REQUEST,
    GET_FEEDBACKS_SUMMARY_NEGATIVE_SUCCESS,
    GET_FEEDBACKS_SUMMARY_NEGATIVE_ERROR,

    GET_FEEDBACKS_SUMMARY_POSITIVE_REQUEST,
    GET_FEEDBACKS_SUMMARY_POSITIVE_SUCCESS,
    GET_FEEDBACKS_SUMMARY_POSITIVE_ERROR,

    GET_BIRTHDAY_COUNT_REQUEST,
    GET_BIRTHDAY_COUNT_SUCCESS,
    GET_BIRTHDAY_COUNT_ERROR,

    GET_TOP_X_ITEMS_QTY_REQUEST,
    GET_TOP_X_ITEMS_QTY_SUCCESS,
    GET_TOP_X_ITEMS_QTY_ERROR,

    GET_LEAST_X_ITEMS_QTY_REQUEST,
    GET_LEAST_X_ITEMS_QTY_SUCCESS,
    GET_LEAST_X_ITEMS_QTY_ERROR,

    GET_TOP_X_ITEMS_AMT_REQUEST,
    GET_TOP_X_ITEMS_AMT_SUCCESS,
    GET_TOP_X_ITEMS_AMT_ERROR,

    GET_LEAST_X_ITEMS_AMT_REQUEST,
    GET_LEAST_X_ITEMS_AMT_SUCCESS,
    GET_LEAST_X_ITEMS_AMT_ERROR,

    GET_NEW_PRODUCT_REQUEST,
    GET_NEW_PRODUCT_SUCCESS,
    GET_NEW_PRODUCT_ERROR,

    GET_NOT_SOLD_ITEMS_REQUEST,
    GET_NOT_SOLD_ITEMS_SUCCESS,
    GET_NOT_SOLD_ITEMS_ERROR,

    GET_TOP_ITEMS_BRANCH_REQUEST,
    GET_TOP_ITEMS_BRANCH_SUCCESS,
    GET_TOP_ITEMS_BRANCH_ERROR,

    GET_AVERAGE_ORDER_PRICE_REQUEST,
    GET_AVERAGE_ORDER_PRICE_SUCCESS,
    GET_AVERAGE_ORDER_PRICE_ERROR,

    GET_AVERAGE_ORDER_PRICE_PERIOD_REQUEST,
    GET_AVERAGE_ORDER_PRICE_PERIOD_SUCCESS,
    GET_AVERAGE_ORDER_PRICE_PERIOD_ERROR,

    GET_LATE_ORDERS_REQUEST,
    GET_LATE_ORDERS_SUCCESS,
    GET_LATE_ORDERS_ERROR,

    GET_TOTAL_ORDERS_REQUEST,
    GET_TOTAL_ORDERS_SUCCESS,
    GET_TOTAL_ORDERS_ERROR,

    GET_TOTAL_ORDERS_YEAR_REQUEST,
    GET_TOTAL_ORDERS_YEAR_SUCCESS,
    GET_TOTAL_ORDERS_YEAR_ERROR,

    GET_CANCELED_ORDERS_REQUEST,
    GET_CANCELED_ORDERS_SUCCESS,
    GET_CANCELED_ORDERS_ERROR,

    GET_SALES_BY_ZONE_REQUEST,
    GET_SALES_BY_ZONE_SUCCESS,
    GET_SALES_BY_ZONE_ERROR,

    GET_SALES_BY_AFFILIATE_REQUEST,
    GET_SALES_BY_AFFILIATE_SUCCESS,
    GET_SALES_BY_AFFILIATE_ERROR,

    GET_SIGNUPS_BY_AFFILIATE_REQUEST,
    GET_SIGNUPS_BY_AFFILIATE_SUCCESS,
    GET_SIGNUPS_BY_AFFILIATE_ERROR,

    GET_TOTAL_DISCOUNTS_REQUEST,
    GET_TOTAL_DISCOUNTS_SUCCESS,
    GET_TOTAL_DISCOUNTS_ERROR,

    GET_TOTAL_PROMO_REQUEST,
    GET_TOTAL_PROMO_SUCCESS,
    GET_TOTAL_PROMO_ERROR,

    GET_QR_COUNT_REQUEST,
    GET_QR_COUNT_SUCCESS,
    GET_QR_COUNT_ERROR,

    GET_DELIVERIES_STATUS_REQUEST,
    GET_DELIVERIES_STATUS_SUCCESS,
    GET_DELIVERIES_STATUS_ERROR,

    GET_SALES_BY_RESTAURANT_REQUEST,
    GET_SALES_BY_RESTAURANT_SUCCESS,
    GET_SALES_BY_RESTAURANT_ERROR,

    GET_SALES_BY_BRANCH_REQUEST,
    GET_SALES_BY_BRANCH_SUCCESS,
    GET_SALES_BY_BRANCH_ERROR,

    GET_AFFILIATE_CLICKS_REQUEST,
    GET_AFFILIATE_CLICKS_SUCCESS,
    GET_AFFILIATE_CLICKS_ERROR,

    GET_AVERAGE_ORDERS_REQUEST,
    GET_AVERAGE_ORDERS_SUCCESS,
    GET_AVERAGE_ORDERS_ERROR,

    GET_AVERAGE_ORDERS_YEAR_REQUEST,
    GET_AVERAGE_ORDERS_YEAR_SUCCESS,
    GET_AVERAGE_ORDERS_YEAR_ERROR,

    GET_SALES_BY_CATEGORY_REQUEST,
    GET_SALES_BY_CATEGORY_SUCCESS,
    GET_SALES_BY_CATEGORY_ERROR,

    GET_SALES_BY_CUSTOMER_REQUEST,
    GET_SALES_BY_CUSTOMER_SUCCESS,
    GET_SALES_BY_CUSTOMER_ERROR,

    GET_AVERAGE_DELIVERY_TIME_REQUEST,
    GET_AVERAGE_DELIVERY_TIME_SUCCESS,
    GET_AVERAGE_DELIVERY_TIME_ERROR,

    GET_ORDERS_STATUS_REQUEST,
    GET_ORDERS_STATUS_SUCCESS,
    GET_ORDERS_STATUS_ERROR

} from '../actions/DashboardActions copy'

const initialState = {
    positioningLoading: false,
    ordersStatusLoading: false,
    avgDeliveryTimeLoading: false,
    salesCustomerLoading: false,
    salesCategoryLoading: false,
    averageOrdersLoading: false,
    averageOrdersYearLoading: false,
    affiliateClicksLoading: false,
    salesBranchLoading: false,
    salesRestaurantLoading: false,
    deliveriesStatusLoading: false,
    qrCountLoading: false,
    promoLoading: false,
    discountsLoading: false,
    signupsAffiliateLoading: false,
    salesAffiliateLoading: false,
    salesZonesLoading: false,
    canceledOrdersLoading: false,
    totalOrdersLoading: false,
    totalOrdersYearLoading: false,
    lateOrdersLoading: false,
    avgOrderPricePeriodLoading: false,
    avgOrderPriceLoading: false,
    topItemsBranchLoading: false,
    notSoldItemsLoading: false,
    newProductsLoading: false,
    leastItemsAmtLoading: false,
    topItemsAmtLoading: false,
    leastItemsQtyLoading: false,
    topItemsQtyLoading: false,
    birthdayCountLoading: false,
    negativeReviewsLoading: false,
    positiveReviewsLoading: false,
    notActiveCustomersLoading: false,
    feedbacksRatingLoading: false,
    complaintsLoading: false,
    newCustomersLoading: false,
    averagePrepTimeLoading: false,
    actionLoading: false,
    layoutLoading: false,
    defaultLayout:null,
    productLayout: {
        lg: [],
        md: [],
        sm: [],
        xs: []
    },
    customersLayout: {
        lg: [],
        md: [],
        sm: [],
        xs: []
    },
    todayLayout: {
        lg: [],
        md: [],
        sm: [],
        xs: []
    },
    transactionLayout: {
        lg: [],
        md: [],
        sm: [],
        xs: []
    },
    consolidatedCurrencyLoading: false,
    restaurantsCurrencyLoading: false,
    setConsolidatedLoading: false,
    restaurantsCurrency: [],
    consolidatedCurrency: [],
    selectedRest: 'all-restaurants',
    productsLoading: false,
    loading: false,
    newProducts: [],
    totalRevenueLoading: false,
    totalRevenueLoadingTransactions: false,
    ordersValueLoading: false,
    ordersCountLoading: false,
    topSpenders: [],
    avgOrderPrice: 0,
    avgOrderPricePeriod: 0,
    lateOrders: [],
    totalOrders: null,
    newCustomers: [],
    canceledOrders: 0,
    //  totalCustomers: 0,
    salesByZone: [],
    salesByAffiliate: [],
    signupsByAffiliate: [],
    totalDiscounts: [],
    totalPromoDiscounts: [],
    topXItemsQty: [],
    topXItemsAmount: [],
    feedbacksAndComplaints: [],
    feedbacksRating: null,
    notActiveCustomers: 0,
    totalRevenuePerMonth: [],
    newProduct: 0,
    leastXItemsQty: [],
    totalRevenuePerMonthTransactions: [],
    leastXItemsAmount: [],
    customerCountValue: 0,
    customersCount: 0,
    totalOrdersByYear: null,
    qrCount: 0,
    feedbacksSummaryPositive: [],
    feedbacksSummaryNegative: [],
    birthdayCount: 0,
    deliveriesStatus: null,
    notSoldItems: [],
    salesByRestaurant: [],
    salesByBranch: [],
    affiliateClicks: [],
    ordersStatus: null,
    topItemsBranch: null,
    avgOrders: 0,
    avgOrdersYear: 0,
    salesByCategory: [],
    salesByCustomer: [],
    avgDeliveryTime: 0,
    avgPrepTime: 0
}

function dashboard(state = initialState, action) {
    switch (action.type) {
        case SET_DEFAULT_LAYOUT_DATA:
            return Object.assign({}, state, {
                defaultLayout: action.payload
            })
        case GET_CARDS_POSITIONING_REQUEST:
            return Object.assign({}, state, {
                positioningLoading: true
            })
        case GET_CARDS_POSITIONING_SUCCESS:
            return Object.assign({}, state, {
                positioningLoading: false
            })
        case GET_CARDS_POSITIONING_ERROR:
            return Object.assign({}, state, {
                positioningLoading: false
            })
        case GET_ORDERS_STATUS_ERROR:
            return Object.assign({}, state, {
                ordersStatusLoading: false,
                ordersStatus: null
            })
        case GET_ORDERS_STATUS_SUCCESS:
            return Object.assign({}, state, {
                ordersStatusLoading: false,
                ordersStatus: action.payload
            })
        case GET_ORDERS_STATUS_REQUEST:
            return Object.assign({}, state, {
                ordersStatusLoading: true
            })
        case GET_AVERAGE_DELIVERY_TIME_ERROR:
            return Object.assign({}, state, {
                avgDeliveryTimeLoading: false,
                avgDeliveryTime: 0
            })
        case GET_AVERAGE_DELIVERY_TIME_SUCCESS:
            return Object.assign({}, state, {
                avgDeliveryTimeLoading: false,
                avgDeliveryTime: action.payload
            })
        case GET_AVERAGE_DELIVERY_TIME_REQUEST:
            return Object.assign({}, state, {
                avgDeliveryTimeLoading: true
            })
        case GET_SALES_BY_CUSTOMER_ERROR:
            return Object.assign({}, state, {
                salesCustomerLoading: false,
                salesByCustomer: []
            })
        case GET_SALES_BY_CUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                salesCustomerLoading: false,
                salesByCustomer: action.payload
            })
        case GET_SALES_BY_CUSTOMER_REQUEST:
            return Object.assign({}, state, {
                salesCustomerLoading: true
            })
        case GET_SALES_BY_CATEGORY_ERROR:
            return Object.assign({}, state, {
                salesCategoryLoading: false,
                salesByCategory: []
            })
        case GET_SALES_BY_CATEGORY_SUCCESS:
            return Object.assign({}, state, {
                salesCategoryLoading: false,
                salesByCategory: action.payload
            })
        case GET_SALES_BY_CATEGORY_REQUEST:
            return Object.assign({}, state, {
                salesCategoryLoading: true
            })
        case GET_AVERAGE_ORDERS_YEAR_ERROR:
            return Object.assign({}, state, {
                averageOrdersYearLoading: false,
                avgOrdersYear: 0
            })
        case GET_AVERAGE_ORDERS_YEAR_SUCCESS:
            return Object.assign({}, state, {
                averageOrdersYearLoading: false,
                avgOrdersYear: action.payload
            })
        case GET_AVERAGE_ORDERS_YEAR_REQUEST:
            return Object.assign({}, state, {
                averageOrdersYearLoading: true
            })
        case GET_AVERAGE_ORDERS_ERROR:
            return Object.assign({}, state, {
                averageOrdersLoading: false,
                avgOrders: 0
            })
        case GET_AVERAGE_ORDERS_SUCCESS:
            return Object.assign({}, state, {
                averageOrdersLoading: false,
                avgOrders: action.payload
            })
        case GET_AVERAGE_ORDERS_REQUEST:
            return Object.assign({}, state, {
                averageOrdersLoading: true
            })
        case GET_AFFILIATE_CLICKS_ERROR:
            return Object.assign({}, state, {
                affiliateClicksLoading: false,
                affiliateClicks: []
            })
        case GET_AFFILIATE_CLICKS_SUCCESS:
            return Object.assign({}, state, {
                affiliateClicksLoading: false,
                affiliateClicks: action.payload
            })
        case GET_AFFILIATE_CLICKS_REQUEST:
            return Object.assign({}, state, {
                affiliateClicksLoading: true
            })
        case GET_SALES_BY_BRANCH_ERROR:
            return Object.assign({}, state, {
                salesBranchLoading: false,
                salesByBranch: []
            })
        case GET_SALES_BY_BRANCH_SUCCESS:
            return Object.assign({}, state, {
                salesBranchLoading: false,
                salesByBranch: action.payload
            })
        case GET_SALES_BY_BRANCH_REQUEST:
            return Object.assign({}, state, {
                salesBranchLoading: true
            })
        case GET_SALES_BY_RESTAURANT_ERROR:
            return Object.assign({}, state, {
                salesRestaurantLoading: false,
                salesByRestaurant: []
            })
        case GET_SALES_BY_RESTAURANT_SUCCESS:
            return Object.assign({}, state, {
                salesRestaurantLoading: false,
                salesByRestaurant: action.payload
            })
        case GET_SALES_BY_RESTAURANT_REQUEST:
            return Object.assign({}, state, {
                salesRestaurantLoading: true
            })
        case GET_DELIVERIES_STATUS_ERROR:
            return Object.assign({}, state, {
                deliveriesStatusLoading: false,
                deliveriesStatus: null
            })
        case GET_DELIVERIES_STATUS_SUCCESS:
            return Object.assign({}, state, {
                deliveriesStatusLoading: false,
                deliveriesStatus: action.payload
            })
        case GET_DELIVERIES_STATUS_REQUEST:
            return Object.assign({}, state, {
                deliveriesStatusLoading: true
            })
        case GET_QR_COUNT_ERROR:
            return Object.assign({}, state, {
                qrCountLoading: false,
                qrCount: 0
            })
        case GET_QR_COUNT_SUCCESS:
            return Object.assign({}, state, {
                qrCountLoading: false,
                qrCount: action.payload
            })
        case GET_QR_COUNT_REQUEST:
            return Object.assign({}, state, {
                qrCountLoading: true,
            })
        case GET_TOTAL_PROMO_ERROR:
            return Object.assign({}, state, {
                promoLoading: false,
                totalPromoDiscounts: []
            })
        case GET_TOTAL_PROMO_SUCCESS:
            return Object.assign({}, state, {
                promoLoading: false,
                totalPromoDiscounts: action.payload
            })
        case GET_TOTAL_PROMO_REQUEST:
            return Object.assign({}, state, {
                promoLoading: true
            })
        case GET_TOTAL_DISCOUNTS_ERROR:
            return Object.assign({}, state, {
                discountsLoading: false,
                totalDiscounts: []
            })
        case GET_TOTAL_DISCOUNTS_SUCCESS:
            return Object.assign({}, state, {
                discountsLoading: false,
                totalDiscounts: action.payload
            })
        case GET_TOTAL_DISCOUNTS_REQUEST:
            return Object.assign({}, state, {
                discountsLoading: true
            })
        case GET_SIGNUPS_BY_AFFILIATE_ERROR:
            return Object.assign({}, state, {
                signupsAffiliateLoading: false,
                signupsByAffiliate: []
            })
        case GET_SIGNUPS_BY_AFFILIATE_SUCCESS:
            return Object.assign({}, state, {
                signupsAffiliateLoading: false,
                signupsByAffiliate: action.payload
            })
        case GET_SIGNUPS_BY_AFFILIATE_REQUEST:
            return Object.assign({}, state, {
                signupsAffiliateLoading: true
            })
        case GET_SALES_BY_AFFILIATE_ERROR:
            return Object.assign({}, state, {
                salesAffiliateLoading: false,
                salesByAffiliate: []
            })
        case GET_SALES_BY_AFFILIATE_SUCCESS:
            return Object.assign({}, state, {
                salesAffiliateLoading: false,
                salesByAffiliate: action.payload
            })
        case GET_SALES_BY_AFFILIATE_REQUEST:
            return Object.assign({}, state, {
                salesAffiliateLoading: true
            })
        case GET_SALES_BY_ZONE_ERROR:
            return Object.assign({}, state, {
                salesZonesLoading: false,
                salesByZone: []
            })
        case GET_SALES_BY_ZONE_SUCCESS:
            return Object.assign({}, state, {
                salesZonesLoading: false,
                salesByZone: action.payload
            })
        case GET_SALES_BY_ZONE_REQUEST:
            return Object.assign({}, state, {
                salesZonesLoading: true
            })
        case GET_CANCELED_ORDERS_ERROR:
            return Object.assign({}, state, {
                canceledOrdersLoading: false,
                canceledOrders: 0
            })
        case GET_CANCELED_ORDERS_SUCCESS:
            return Object.assign({}, state, {
                canceledOrdersLoading: false,
                canceledOrders: action.payload
            })
        case GET_CANCELED_ORDERS_REQUEST:
            return Object.assign({}, state, {
                canceledOrdersLoading: true
            })
        case GET_TOTAL_ORDERS_YEAR_ERROR:
            return Object.assign({}, state, {
                totalOrdersYearLoading: false,
                totalOrdersByYear: null
            })
        case GET_TOTAL_ORDERS_YEAR_SUCCESS:
            return Object.assign({}, state, {
                totalOrdersYearLoading: false,
                totalOrdersByYear: action.payload
            })
        case GET_TOTAL_ORDERS_YEAR_REQUEST:
            return Object.assign({}, state, {
                totalOrdersYearLoading: true
            })

        case GET_TOTAL_ORDERS_ERROR:
            return Object.assign({}, state, {
                totalOrders: false,
                totalOrders: null
            })
        case GET_TOTAL_ORDERS_SUCCESS:
            return Object.assign({}, state, {
                totalOrdersLoading: false,
                totalOrders: action.payload
            })
        case GET_TOTAL_ORDERS_REQUEST:
            return Object.assign({}, state, {
                totalOrdersLoading: true
            })
        case GET_LATE_ORDERS_ERROR:
            return Object.assign({}, state, {
                lateOrdersLoading: false,
                lateOrders: []
            })
        case GET_LATE_ORDERS_SUCCESS:
            return Object.assign({}, state, {
                lateOrdersLoading: false,
                lateOrders: action.payload
            })
        case GET_LATE_ORDERS_REQUEST:
            return Object.assign({}, state, {
                lateOrdersLoading: true
            })
        case GET_AVERAGE_ORDER_PRICE_PERIOD_ERROR:
            return Object.assign({}, state, {
                avgOrderPricePeriodLoading: false,
                avgOrderPricePeriod: 0
            })
        case GET_AVERAGE_ORDER_PRICE_PERIOD_SUCCESS:
            return Object.assign({}, state, {
                avgOrderPricePeriodLoading: false,
                avgOrderPricePeriod: action.payload
            })
        case GET_AVERAGE_ORDER_PRICE_PERIOD_REQUEST:
            return Object.assign({}, state, {
                avgOrderPricePeriodLoading: true
            })
        case GET_AVERAGE_ORDER_PRICE_ERROR:
            return Object.assign({}, state, {
                avgOrderPriceLoading: false,
                avgOrderPrice: 0
            })
        case GET_AVERAGE_ORDER_PRICE_SUCCESS:
            return Object.assign({}, state, {
                avgOrderPriceLoading: false,
                avgOrderPrice: action.payload
            })
        case GET_AVERAGE_ORDER_PRICE_REQUEST:
            return Object.assign({}, state, {
                avgOrderPriceLoading: true
            })
        case GET_TOP_ITEMS_BRANCH_ERROR:
            return Object.assign({}, state, {
                topItemsBranchLoading: false,
                topItemsBranch: null
            })
        case GET_TOP_ITEMS_BRANCH_SUCCESS:
            return Object.assign({}, state, {
                topItemsBranchLoading: false,
                topItemsBranch: action.payload
            })
        case GET_TOP_ITEMS_BRANCH_REQUEST:
            return Object.assign({}, state, {
                topItemsBranchLoading: true
            })
        case GET_NOT_SOLD_ITEMS_ERROR:
            return Object.assign({}, state, {
                notSoldItemsLoading: false,
                notSoldItems: []
            })
        case GET_NOT_SOLD_ITEMS_SUCCESS:
            return Object.assign({}, state, {
                notSoldItemsLoading: false,
                notSoldItems: action.payload
            })
        case GET_NOT_SOLD_ITEMS_REQUEST:
            return Object.assign({}, state, {
                notSoldItemsLoading: true
            })
        case GET_NEW_PRODUCT_ERROR:
            return Object.assign({}, state, {
                newProductsLoading: false,
                newProduct: 0
            })
        case GET_NEW_PRODUCT_SUCCESS:
            return Object.assign({}, state, {
                newProductsLoading: false,
                newProduct: action.payload
            })
        case GET_NEW_PRODUCT_REQUEST:
            return Object.assign({}, state, {
                newProductsLoading: true
            })
        case GET_LEAST_X_ITEMS_AMT_ERROR:
            return Object.assign({}, state, {
                leastItemsAmtLoading: false,
                leastXItemsAmt: []
            })
        case GET_LEAST_X_ITEMS_AMT_SUCCESS:
            return Object.assign({}, state, {
                leastItemsAmtLoading: false,
                leastXItemsAmt: action.payload
            })
        case GET_LEAST_X_ITEMS_AMT_REQUEST:
            return Object.assign({}, state, {
                leastItemsAmtLoading: true
            })
        case GET_TOP_X_ITEMS_AMT_ERROR:
            return Object.assign({}, state, {
                topItemsAmtLoading: false,
                topXItemsAmt: []
            })
        case GET_TOP_X_ITEMS_AMT_SUCCESS:
            return Object.assign({}, state, {
                topItemsAmtLoading: false,
                topXItemsAmt: action.payload
            })
        case GET_TOP_X_ITEMS_AMT_REQUEST:
            return Object.assign({}, state, {
                topItemsAmtLoading: true
            })
        case GET_LEAST_X_ITEMS_QTY_ERROR:
            return Object.assign({}, state, {
                leastItemsQtyLoading: false,
                leastXItemsQty: []
            })
        case GET_LEAST_X_ITEMS_QTY_SUCCESS:
            return Object.assign({}, state, {
                leastItemsQtyLoading: false,
                leastXItemsQty: action.payload
            })
        case GET_LEAST_X_ITEMS_QTY_REQUEST:
            return Object.assign({}, state, {
                leastItemsQtyLoading: true
            })
        case GET_TOP_X_ITEMS_QTY_ERROR:
            return Object.assign({}, state, {
                topItemsQtyLoading: false,
                topXItemsQty: []
            })
        case GET_TOP_X_ITEMS_QTY_SUCCESS:
            return Object.assign({}, state, {
                topItemsQtyLoading: false,
                topXItemsQty: action.payload
            })
        case GET_TOP_X_ITEMS_QTY_REQUEST:
            return Object.assign({}, state, {
                topItemsQtyLoading: true
            })
        case GET_BIRTHDAY_COUNT_ERROR:
            return Object.assign({}, state, {
                birthdayCountLoading: false,
                birthdayCount: 0
            })
        case GET_BIRTHDAY_COUNT_SUCCESS:
            return Object.assign({}, state, {
                birthdayCountLoading: false,
                birthdayCount: action.payload
            })
        case GET_BIRTHDAY_COUNT_REQUEST:
            return Object.assign({}, state, {
                birthdayCountLoading: true
            })
        case GET_FEEDBACKS_SUMMARY_POSITIVE_ERROR:
            return Object.assign({}, state, {
                positiveReviewsLoading: false,
                feedbacksSummaryPositive: []
            })
        case GET_FEEDBACKS_SUMMARY_POSITIVE_SUCCESS:
            return Object.assign({}, state, {
                positiveReviewsLoading: false,
                feedbacksSummaryPositive: action.payload
            })
        case GET_FEEDBACKS_SUMMARY_POSITIVE_REQUEST:
            return Object.assign({}, state, {
                positiveReviewsLoading: true
            })
        case GET_FEEDBACKS_SUMMARY_NEGATIVE_ERROR:
            return Object.assign({}, state, {
                negativeReviewsLoading: false,
                feedbacksSummaryNegative: []
            })
        case GET_FEEDBACKS_SUMMARY_NEGATIVE_SUCCESS:
            return Object.assign({}, state, {
                negativeReviewsLoading: false,
                feedbacksSummaryNegative: action.payload
            })
        case GET_FEEDBACKS_SUMMARY_NEGATIVE_REQUEST:
            return Object.assign({}, state, {
                negativeReviewsLoading: true
            })
        case GET_NOT_ACTIVE_CUSTOMERS_ERROR:
            return Object.assign({}, state, {
                notActiveCustomersLoading: false,
                notActiveCustomers: null
            })
        case GET_NOT_ACTIVE_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, {
                notActiveCustomersLoading: false,
                notActiveCustomers: action.payload
            })
        case GET_NOT_ACTIVE_CUSTOMERS_REQUEST:
            return Object.assign({}, state, {
                notActiveCustomersLoading: true
            })
        case GET_FEEDBACKS_RATING_ERROR:
            return Object.assign({}, state, {
                feedbacksRatingLoading: false,
                feedbacksRating: null
            })
        case GET_FEEDBACKS_RATING_SUCCESS:
            return Object.assign({}, state, {
                feedbacksRatingLoading: false,
                feedbacksRating: action.payload
            })
        case GET_FEEDBACKS_RATING_REQUEST:
            return Object.assign({}, state, {
                feedbacksRatingLoading: true
            })
        case GET_FEEDBACKS_COMPLAINTS_ERROR:
            return Object.assign({}, state, {
                complaintsLoading: false
            })
        case GET_FEEDBACKS_COMPLAINTS_SUCCESS:
            return Object.assign({}, state, {
                complaintsLoading: false,
                feedbacksAndComplaints: action.payload
            })
        case GET_FEEDBACKS_COMPLAINTS_REQUEST:
            return Object.assign({}, state, {
                complaintsLoading: true
            })
        case GET_NEW_CUSTOMERS_ERROR:
            return Object.assign({}, state, {
                newCustomersLoading: false,
                newCustomers: []
            })
        case GET_NEW_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, {
                newCustomersLoading: false,
                newCustomers: action.payload
            })
        case GET_NEW_CUSTOMERS_REQUEST:
            return Object.assign({}, state, {
                newCustomersLoading: true
            })
        case GET_AVERAGE_PREPARATION_TIME_ERROR:
            return Object.assign({}, state, {
                averagePrepTimeLoading: false,
                avgPrepTime: 0
            })
        case GET_AVERAGE_PREPARATION_TIME_SUCCESS:
            return Object.assign({}, state, {
                averagePrepTimeLoading: false,
                avgPrepTime: action.payload
            })
        case GET_AVERAGE_PREPARATION_TIME_REQUEST:
            return Object.assign({}, state, {
                averagePrepTimeLoading: true
            })
        case SET_TODAY_LAYOUT:
            return Object.assign({}, state, {
                todayLayout: action.payload
            })
        case SET_PRODUCT_LAYOUT:
            return Object.assign({}, state, {
                productLayout: action.payload
            })
        case SET_CUSTOMERS_LAYOUT:
            return Object.assign({}, state, {
                customersLayout: action.payload
            })
        case SET_TRANSACTIONS_LAYOUT:
            return Object.assign({}, state, {
                transactionLayout: action.payload
            })
        case SET_CARDS_POSITIONING_STARTED:
            return Object.assign({}, state, {
                actionLoading: true
            })
        case SET_CARDS_POSITIONING_ENDED:
            return Object.assign({}, state, {
                actionLoading: false
            })
        case SET_CONSOLIDATED_CURRENCY_ENDED:
            return Object.assign({}, state, {
                setConsolidatedLoading: false
            })
        case SET_CONSOLIDATED_CURRENCY_STARTED:
            return Object.assign({}, state, {
                setConsolidatedLoading: true
            })
        case GET_RESTAURANTS_CURRENCY_ERROR:
            return Object.assign({}, state, {
                restaurantsCurrencyLoading: false,
                restaurantsCurrency: []
            })
        case GET_RESTAURANTS_CURRENCY_SUCCESS:
            return Object.assign({}, state, {
                restaurantsCurrencyLoading: false,
                restaurantsCurrency: action.payload
            })
        case GET_RESTAURANTS_CURRENCY_REQUEST:
            return Object.assign({}, state, {
                restaurantsCurrencyLoading: true
            })
        case GET_CONSOLIDATED_CURRENCY_ERROR:
            return Object.assign({}, state, {
                consolidatedCurrencyLoading: false,
                consolidatedCurrency: []
            })
        case GET_CONSOLIDATED_CURRENCY_SUCCESS:
            return Object.assign({}, state, {
                consolidatedCurrencyLoading: false,
                consolidatedCurrency: action.payload
            })
        case GET_CONSOLIDATED_CURRENCY_REQUEST:
            return Object.assign({}, state, {
                consolidatedCurrencyLoading: true
            })
        case SET_DASHBOARD_REST:
            return Object.assign({}, state, {
                selectedRest: action.payload
            })
        case GET_DASHBOARD_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                totalRevenueLoading: false,
                totalRevenueLoadingTransactions: false,
                newCustomers: action.payload.newCustomers,
                feedbacksAndComplaints: action.payload.feedbacksAndComplaints,
                feedbacksRating: action.payload.feedbacksRating,
                notActiveCustomers: action.payload.notActiveCustomers,
                customerCountValue: action.payload.customerCountValue,
                customersCount: action.payload.customersCount,
                feedbacksSummaryPositive: action.payload.feedbacksSummaryPositive,
                feedbacksSummaryNegative: action.payload.feedbacksSummaryNegative,
                birthdayCount: action.payload.birthdayCount,
                avgOrders: action.payload.avgOrders,
                salesByCustomer: action.payload.salesByCustomer
            })
        case GET_DASHBOARD_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                totalRevenueLoading: false,
                totalRevenueLoadingTransactions: false,
                topXItemsQty: action.payload.topXItemsQty,
                topXItemsAmount: action.payload.topXItemsAmount,
                totalRevenuePerMonth: action.payload.totalRevenuePerMonth,
                totalRevenuePerMonthTransactions: action.payload.totalRevenuePerMonthTransactions,
                newProduct: action.payload.newProduct,
                leastXItemsQty: action.payload.leastXItemsQty,
                leastXItemsAmount: action.payload.leastXItemsAmount,
                totalOrdersByYear: action.payload.totalOrdersByYear,
                notSoldItems: action.payload.notSoldItems,
                topItemsBranch: action.payload.topItemsBranch
            })
        case GET_DASHBOARD_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                totalRevenueLoading: false,
                totalRevenueLoadingTransactions: false,
                topSpenders: action.payload.topSpenders,
                avgOrderPrice: action.payload.avgOrderPrice,
                avgOrderPricePeriod: action.payload.avgOrderPricePeriod,
                lateOrders: action.payload.lateOrders,
                totalOrders: action.payload.totalOrders,
                canceledOrders: action.payload.canceledOrders,
                salesByZone: action.payload.salesByZone,
                salesByAffiliate: action.payload.salesByAffiliate,
                signupsByAffiliate: action.payload.signupsByAffiliate,
                //NEW
                totalDiscounts: action.payload.totalDiscounts,
                totalPromoDiscounts: action.payload.totalPromoDiscounts,
                topXItemsQty: action.payload.topXItemsQty,
                totalRevenuePerMonth: action.payload.totalRevenuePerMonth,
                totalRevenuePerMonthTransactions: action.payload.totalRevenuePerMonthTransactions,
                totalOrdersByYear: action.payload.totalOrdersByYear,
                qrCount: action.payload.qrCount,
                deliveriesStatus: action.payload.deliveriesStatus,
                salesByRestaurant: action.payload.salesByRestaurant,
                salesByBranch: action.payload.salesByBranch,
                affiliateClicks: action.payload.affiliateClicks,
                avgOrders: action.payload.avgOrders,
                avgOrdersYear: action.payload.avgOrdersYear,
                salesByCategory: action.payload.salesByCategory,
                salesByCustomer: action.payload.salesByCustomer,
                avgDeliveryTime: action.payload.avgDeliveryTime,
                avgPrepTime: action.payload.avgPrepTime
            })
        case GET_DASHBOARD_NEW_PRODUCTS_ERROR:
            return Object.assign({}, state, {
                productsLoading: false
            })
        case GET_DASHBOARD_NEW_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                productsLoading: false,
                newProducts: action.payload
            })
        case GET_DASHBOARD_NEW_PRODUCTS_REQUEST:
            return Object.assign({}, state, {
                productsLoading: true
            })
        case GET_DASHBOARD_ORDERS_COUNT_ERROR:
            return Object.assign({}, state, {
                ordersCountLoading: false,
                customersCount: 0
            })
        case GET_DASHBOARD_ORDERS_COUNT_SUCCESS:
            return Object.assign({}, state, {
                ordersCountLoading: false,
                customersCount: action.payload
            })
        case GET_DASHBOARD_ORDERS_COUNT_REQUEST:
            return Object.assign({}, state, {
                ordersCountLoading: true
            })
        case GET_DASHBOARD_ORDERS_VALUE_ERROR:
            return Object.assign({}, state, {
                ordersValueLoading: false,
                customerCountValue: 0
            })
        case GET_DASHBOARD_ORDERS_VALUE_SUCCESS:
            return Object.assign({}, state, {
                ordersValueLoading: false,
                customerCountValue: action.payload
            })
        case GET_DASHBOARD_ORDERS_VALUE_REQUEST:
            return Object.assign({}, state, {
                ordersValueLoading: true
            })
        case GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR:
            return Object.assign({}, state, {
                totalRevenueLoadingTransactions: false,
                totalRevenuePerMonthTransactions: []
            })
        case GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                totalRevenueLoadingTransactions: false,
                totalRevenuePerMonthTransactions: action.payload
            })
        case GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST:
            return Object.assign({}, state, {
                totalRevenueLoadingTransactions: true
            })
        case GET_DASHBOARD_TOTAL_REVENUE_ERROR:
            return Object.assign({}, state, {
                totalRevenueLoading: false,
                totalRevenuePerMonth: []
            })
        case GET_DASHBOARD_TOTAL_REVENUE_SUCCESS:
            return Object.assign({}, state, {
                totalRevenueLoading: false,
                totalRevenuePerMonth: action.payload
            })
        case GET_DASHBOARD_TOTAL_REVENUE_REQUEST:
            return Object.assign({}, state, {
                totalRevenueLoading: true
            })
        case CLEAR_DASHBOARD_LOADING:
            return Object.assign({}, state, {
                loading: false,
            })
        case GET_DASHBOARD_REQUEST:
        case GET_DASHBOARD_TRANSACTION_REQUEST:
        case GET_DASHBOARD_PRODUCTS_REQUEST:
        case GET_DASHBOARD_CUSTOMERS_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case GET_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                totalRevenueLoading: false,
                totalRevenueLoadingTransactions: false,
                topSpenders: action.payload.topSpenders,
                avgOrderPrice: action.payload.avgOrderPrice,
                lateOrders: action.payload.lateOrders,
                totalOrders: action.payload.totalOrders,
                newCustomers: action.payload.newCustomers,
                canceledOrders: action.payload.canceledOrders,
                // totalCustomers: action.payload.totalCustomers,
                salesByZone: action.payload.salesByZone,
                salesByAffiliate: action.payload.salesByAffiliate,
                signupsByAffiliate: action.payload.signupsByAffiliate,
                totalDiscounts: action.payload.totalDiscounts,
                totalPromoDiscounts: action.payload.totalPromoDiscounts,
                topXItemsQty: action.payload.topXItemsQty,
                topXItemsAmount: action.payload.topXItemsAmount,
                feedbacksAndComplaints: action.payload.feedbacksAndComplaints,
                feedbacksRating: action.payload.feedbacksRating,
                notActiveCustomers: action.payload.notActiveCustomers,
                totalRevenuePerMonth: action.payload.totalRevenuePerMonth,
                newProduct: action.payload.newProduct,
                leastXItemsQty: action.payload.leastXItemsQty,
                totalRevenuePerMonthTransactions: action.payload.totalRevenuePerMonth,
                leastXItemsAmount: action.payload.leastXItemsAmount,
                avgOrderPricePeriod: action.payload.avgOrderPricePeriod,
                customerCountValue: action.payload.customerCountValue,
                customersCount: action.payload.customersCount,
                totalOrdersByYear: action.payload.totalOrdersByYear,
                qrCount: action.payload.qrCount,
                feedbacksSummaryPositive: action.payload.feedbacksSummaryPositive,
                feedbacksSummaryNegative: action.payload.feedbacksSummaryNegative,
                birthdayCount: action.payload.birthdayCount,
                deliveriesStatus: action.payload.deliveriesStatus,
                notSoldItems: action.payload.notSoldItems,
                ordersStatus: action.payload.ordersStatus,
                avgDeliveryTime: action.payload.avgDeliveryTime,
                avgPrepTime: action.payload.avgPrepTime
            })
        case GET_DASHBOARD_ERROR:
        case GET_DASHBOARD_TRANSACTION_ERROR:
        case GET_DASHBOARD_PRODUCTS_ERROR:
        case GET_DASHBOARD_CUSTOMERS_ERROR:
            return initialState
        default:
            return state
    }
}
export default dashboard